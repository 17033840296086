.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 30%;
}

.image-container {
    display: flex;
    height: 100vh;
}

.link-txt {
    color: black;
    font-weight: bold;
    margin-left: 7px;
}

.img {
    background-image: url('../../../assets/ayr_background.png');
    width: 70%;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .img {
        display: none;
    }

    .login-container {
        width: 100%;
    }
}
