.edit-user-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #05a955;
    border-color: #05a955;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner  {
    border-color: #05a955;
}
