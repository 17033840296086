.other-networks-row {
    margin-left: 35px;
    margin-top: 15px;
}

.sub-header-txt {
    text-align: center;
    margin: 0;
    font-weight: 500;
}

.networks-table .ant-table-thead .ant-table-cell {
    background: #05a955;
    color: white;
}

.ant-pagination-item-active a {
    color: #05a955;
}

.ant-pagination-item-active {
    border-color: #05a955;
}

.ant-pagination-item-active a:hover {
    color: #05a955!important;
}

.ant-pagination-item-active:hover {
    border-color: #05a955!important;
}

.ant-pagination-item a:hover {
    color: #05a955;
}

.ant-pagination-item:hover {
    border-color: #05a955;
}

.ant-pagination-next .ant-pagination-item-link:hover {
    color: #05a955;
    border: 1px solid #05a955;
}

.ant-pagination-prev .ant-pagination-item-link:hover {
    color: #05a955;
    border: 1px solid #05a955;
}

.ant-pagination-next .ant-pagination-item-link:hover:disabled {
    color: #d9d9d9;
    border: 1px solid #d9d9d9;
}

.ant-pagination-prev .ant-pagination-item-link:hover:disabled {
    color: #d9d9d9;
    border: 1px solid #d9d9d9;
}

.ant-table-column-sorter-up.active {
    color: #05a955;
}

.ant-table-column-sorter-down.active {
    color: #05a955;
}

.ant-table-row-expand-icon-cell .ant-table-row-expand-icon:hover {
    border-color: #05a955;
    color: #05a955;
}
