.device-row {
    cursor: pointer;
}

.ant-table-thead .ant-table-cell {

}

.devices-table .ant-table-thead .ant-table-cell {
    background: #7a7a7a!important;
    color: white;
}
