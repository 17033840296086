.register-form-container {
    margin-top: 20px;
    width: 600px;
}

.form-block {
    width: 280px;
}

.form-display {
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 768px) {
    .register-form-container {
        width: 85%;
        margin: auto;
    }

    .form-block {
        width: 100%;
    }

    .form-display {
        display: block;
    }

}
