.name-col-options {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-left: 10px;
}

.save-button {
    margin-right: 8px;
}

.check-icon {
    font-size: 20px;
    color: green;
    margin-right: 10px;
}

.cancel-icon {
    font-size: 20px;
    color: red;
}

.form-item {
    margin: 0;
}

.editable-cell {
    height: 25px;
    width: 100%;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.bold-txt {
    font-weight: bold;
}

.line-margin {
    margin-bottom: 15px;
}
